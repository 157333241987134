<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button" :id="`dropdownMenuClickableInside-${componentName}`"
      data-bs-toggle="dropdown" data-bs-auto-close="outside" ref="destinationDropDown">

      <strong>
        <span v-if="componentName==='destination'">{{ $t("sabre.search-panel.from-where")}}</span>
        <span v-if="componentName==='subject'">{{ $t('search-tab.choose-subject')}}</span>
        <span v-if="componentName==='months'">{{ $t('search-tab.choose-date')}}</span>
      </strong>
      <span v-if="componentName==='destination'"><i class="fa-solid fa-location-dot"></i></span>
      <span v-if="componentName==='subject'"><i class="fa-solid fa-object-ungroup"></i></span>
      <span v-if="componentName==='months'"><i class="fa-regular fa-calendar"></i></span>
      <small>
        <span v-if="componentName==='destination'">{{ showingDestString }}</span>
        <span v-if="componentName==='subject'">{{ showingSubjectString }}</span>
        <span v-if="componentName==='months'">{{ showingMonthsString }}</span>
      </small>

    </button>

    <select-destination :propsClassName="dropdownClassName" type="dropdown" :aria-labelledby="`dropdownMenuClickableInside-${componentName}`"
      :category="category"
      @finished="closeDropdown"
      :options="destinationOptions"
      :isMultiple="isORG"
      :hotOptions="hotOptions" v-if="componentName==='destination'"/>

    <!-- <select-subject-months :propsClassName="dropdownClassName" type="dropdown" :aria-labelledby="`dropdownMenuClickableInside-${componentName}`"
      :category="category"
      @finished="closeDropdown"
      :isMultiple="true"
      :componentName="componentName"
      :options="subjectOptionsOrg" v-if="componentName==='subject'"/> -->

    <select-subject-months :propsClassName="dropdownClassName" type="dropdown" :aria-labelledby="`dropdownMenuClickableInside-${componentName}`"
      :category="category"
      @finished="closeDropdown"
      :isMultiple="true"
      :componentName="componentName"
      :options="orgMonthsOptions" v-if="componentName==='months'"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'desktop-dropdown-FH',
  mixins: [dropdownOpenState],
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    category: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    componentName: {
      type: String,
      default: 'destination',
    },
  },
  components: {
    selectDestination: () => import('./FHSelectDestination'),
    selectSubjectMonths: () => import('./FHSelectSubjectMonths'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      chosenDestinationCodes: 'GET_FH_CHOSEN_DESTINATIONS',
      isLanding: 'GET_IS_LANDING_PAGE',
      calendarData: 'GET_CALENDAR_DATA',
      subCategoryList: 'GET_FH_SUB_CATEGORY_LIST',
      storedSubjects: 'GET_FH_SEARCH_SUBJECTS',
      storedMonths: 'GET_FH_YEAR_MONTHS',

      selectedSubCategory: 'GET_FH_SELECTED_SUB_CATE',
    }),
    hotDestinations() {
      return this.category?.hotDestinations ?? [];
    },
    showingDestString() {
      const { category, chosenDestinationCodes, lang } = this;
      if (!chosenDestinationCodes) return '';

      const current = category.destinations?.find((item) => item.code === chosenDestinationCodes[0]) || '';
      if (!current) return '';
      return current.countryName[lang] ? `${current.name[lang]}, ${current.countryName[lang]}` : current.name[lang];
    },
    showingSubjectString() {
      const { subCategoryList, storedSubjects } = this;
      if (!storedSubjects || !subCategoryList) return '';
      const selectedCount = storedSubjects.length;

      return selectedCount < 2 ? subCategoryList.find((subject) => subject.id === storedSubjects[0])?.nameEn || '' : `${selectedCount} selected`;
    },
    showingMonthsString() {
      const { storedMonths } = this;
      if (!storedMonths || !storedMonths.length) return '';
      const selectedCount = storedMonths.length;
      return selectedCount < 2 ? storedMonths[0] : `${selectedCount} selected`;
    },
    destinationOptions() {
      const { category, lang, subCategoryList, selectedSubCategory } = this;
      const subDestList = (selectedSubCategory && selectedSubCategory.length)
        ? subCategoryList.filter((sub) => selectedSubCategory.includes(sub.id)).reduce((total, curr) => (total.concat(curr.destinations)), []) : null;
      const destList = subDestList ? category.destinations.filter((dest) => subDestList.includes(dest.code)) : category.destinations;

      const list = destList.map((item, inx) => ({
        id: inx,
        code: item.code,
        // label: item.countryName[lang] ? `${item.name[lang]}, ${item.countryName[lang]}` : item.name[lang],
        label: item.name[lang],
      }));
      list.sort((a, b) => (a.label < b.label ? -1 : 1));
      return list;
    },
    hotOptions() {
      const { hotDestinations, category, lang } = this;
      return hotDestinations.map((dest, inx) => {
        const destination = category.destinations.find((d) => d.code === dest) || null;
        return {
          id: inx,
          code: dest,
          // label: destination?.countryName[lang] ? `${destination?.name[lang] || ''}, ${destination?.countryName[lang] || ''}` : destination?.name[lang] || '',
          label: destination?.name[lang] || '',
        };
      });
    },
    isORG() {
      return this.category.dealTypeCode === 'Organize_tour_packages';
    },
    subjectOptionsOrg() {
      const { category, calendarData, chosenDestinationCodes, subCategoryList, lang } = this;
      if (!category || !calendarData || !chosenDestinationCodes || !subCategoryList) return [];
      const ableDate = calendarData.filter(
        (rec) => chosenDestinationCodes.findIndex((d) => (d === rec.dest)) > -1 && category.subjectCodes.includes(rec.sub),
      );

      const list = subCategoryList.filter((sub) => (ableDate.findIndex((date) => (sub.subjectCodes.includes(date.sub))) > -1));
      return list.map((item) => ({
        id: item.id,
        value: item.id,
        label: item[`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`],
        list: item.subjectCodes,
      }));
    },
    // monthsOptions() {
    //   const { category, calendarData, chosenDestinationCodes, subjectOptionsOrg } = this;
    //   if (!category || !calendarData || !chosenDestinationCodes || !subjectOptionsOrg || !subjectOptionsOrg.length) return [];

    //   const subjectList = subjectOptionsOrg.reduce((total, item) => (total.concat(item.list)), []);
    //   const list = calendarData.filter(
    //     (rec) => subjectList.indexOf(rec.sub) > -1 && chosenDestinationCodes.findIndex((d) => (d === rec.dest)) > -1,
    //   );

    //   dayjs.locale(this.lang);

    //   const fltListBySameMonth = list.reduce((total, item) => {
    //     if (total.findIndex((t) => (t.date.indexOf(item.date.substr(0, 7)) > -1)) === -1) return total.concat(item);
    //     return total;
    //   }, []);
    //   fltListBySameMonth.sort((a, b) => (new Date(a.date) - new Date(b.date)));

    //   return fltListBySameMonth.map((item) => {
    //     const nowDate = dayjs(item.date);
    //     const month = nowDate.format('MMMM').toLowerCase(), year = nowDate.year();
    //     return {
    //       id: nowDate.format('YYYY-MM'),
    //       value: nowDate.format('YYYY-MM'),
    //       label: `${month} ${year}`,
    //     };
    //   });
    // },
    orgMonthsOptions() {
      const { category, calendarData, chosenDestinationCodes, subCategoryList, selectedSubCategory } = this;
      if (!category || !calendarData || !chosenDestinationCodes) return [];

      const allCategoryList = subCategoryList.filter((sub) => (sub.packCategoryCode === category.dealTypeCode));

      const selected = (selectedSubCategory && selectedSubCategory.length) ? allCategoryList.filter((sub) => selectedSubCategory.includes(sub.id)) : allCategoryList;
      const subjectList = selected.reduce((total, item) => (total.concat(item.subjectCodes)), []);
      const list = calendarData.filter(
        (rec) => subjectList.indexOf(rec.sub) > -1 && chosenDestinationCodes.findIndex((d) => (d === rec.dest)) > -1,
      );

      dayjs.locale(this.lang);

      const fltListBySameMonth = list.reduce((total, item) => {
        if (total.findIndex((t) => (t.date.indexOf(item.date.substr(0, 7)) > -1)) === -1) return total.concat(item);
        return total;
      }, []);
      fltListBySameMonth.sort((a, b) => (new Date(a.date) - new Date(b.date)));

      return fltListBySameMonth.map((item) => {
        const nowDate = dayjs(item.date);
        const month = nowDate.format('MMMM').toLowerCase(), year = nowDate.year();
        return {
          id: nowDate.format('YYYY-MM'),
          value: nowDate.format('YYYY-MM'),
          label: `${month} ${year}`,
        };
      });
    },
    dropdownClassName() {
      const { showed, device } = this;
      return `dropdown-menu fromwheredropdown ${showed ? 'show' : ''}${device === 'mobile' ? ' fixedbox' : ''}`;
    },
  },
  data: () => ({
    showed: false,
  }),
  mounted() {
    this.getDestinationFromQuery();
  },
  methods: {
    closeDropdown() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.destinationDropDown).hide();
      this.$emit('closed', this.componentName);
      this.showed = false;
    },
    getDestinationFromQuery() {
      const { query } = this.$route;
      const { categoryId } = query;
      if (this.isLanding && (this.query.dealType === 'vacation_pack' || this.query.dealType === 'Flight_Only' || !this.query.dealType)) {
        const destCode = this.query.destination || null;
        this.$store.commit('SET_CHOSEN_DESTINATIONS', destCode ? [destCode] : []);
      } else if (categoryId !== 'Organize_tour_packages') {
        const strDestination = query.dest || query.destination || '',
          destCodesList = strDestination !== '' ? decodeURIComponent(strDestination).split(',') : [];
        this.$store.commit('SET_CHOSEN_DESTINATIONS', destCodesList);
      } else {
        this.$store.commit('SET_CHOSEN_DESTINATIONS', []);
      }
    },
    openDropdown() {
      this.showed = true;
    },
  },
};
</script>

<style scoped>
/* .fromwheredropdown:not(.fixedbox) {
  top: auto !important;
  left: auto !important;
} */
</style>
